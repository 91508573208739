import {OpenAIMessage} from '../chat/types';
import type {ChatHistoryTrimmerOptions} from './chat-history-trimmer';
// @ts-ignore
import * as worker from './worker';

export function runChatTrimmer(
    messages: OpenAIMessage[],
    options: ChatHistoryTrimmerOptions,
) {
    return worker.runChatTrimmer(messages, options);
}

export function countTokens(messages: OpenAIMessage[]) {
    return worker.countTokensForMessages(messages);
}
