import EventEmitter from 'events';
import {
  createChatCompletion,
  actionsStore,
  createStreamingChatCompletion,
  createThreadChat,
  fileUpload,
} from './openai';
import { PluginContext } from '../plugins/plugin-context';
import { pluginRunner } from '../plugins/plugin-runner';
import {
  Chat,
  FileUploaded,
  Message,
  OpenAIMessage,
  Parameters,
} from './types';
import { EventEmitterAsyncIterator } from '../utils/event-emitter-async-iterator';
import { YChat } from './y-chat';
import { OptionsManager } from '../options';
import OpenAI from 'openai';
import { AppConfig } from '../config';
import { MemoryPluginOptions } from '../../plugins/memory';
import { SetStateAction } from 'react';

export class ReplyRequest extends EventEmitter {
  private mutatedMessages: OpenAIMessage[] = [];
  private mutatedParameters: Parameters;
  private lastChunkReceivedAt: number = 0;
  private timer: any;
  private done: boolean = false;
  private content = '';
  private cancelRequestStream: any;

  constructor(
    private chat: Chat,
    private yChat: YChat,
    private messages: Message[],
    private replyID: string,
    private requestedParameters: Parameters,
    private pluginOptions: OptionsManager,
  ) {
    super();

    this.mutatedParameters = {
      ...requestedParameters,
      context: this.yChat.aiContext,
    };
    delete this.mutatedParameters.apiKey;
  }

  private pluginContext = (pluginID: string) =>
    ({
      getOptions: () => {
        return this.pluginOptions.getAllOptions(pluginID, this.chat.id);
      },

      setOptions: <T = any>(optionID: string, v: SetStateAction<T>) => {
        if (typeof v === 'function') {
          const newV = (v as any)(
            this.pluginOptions.getValidatedOption(
              pluginID,
              optionID,
              this.chat.id,
            ),
          );
          this.pluginOptions.setOption(pluginID, optionID, newV, this.chat.id);
        } else {
          this.pluginOptions.setOption(
              pluginID,
              optionID,
              v,
              this.chat.id,
          );
        }
      },

      getCurrentChat: () => {
        return this.chat;
      },

      createChatCompletion: (
        messages: OpenAIMessage[],
        _parameters: Parameters,
      ) =>
        createChatCompletion(messages, {
          ..._parameters,
          apiKey: this.requestedParameters.apiKey,
        }),

      setChatTitle: async (title: string) => {
        this.yChat.title = title;
      },
    }) as PluginContext;

  private scheduleTimeout(interval = 60) {
    this.lastChunkReceivedAt = Date.now();

    clearInterval(this.timer);

    this.timer = setInterval(() => {
      const sinceLastChunk = Date.now() - this.lastChunkReceivedAt;
      if (sinceLastChunk > interval * 1000 && !this.done) {
        this.onError(`no response from OpenAI in the last ${interval} seconds`);
      }
    }, 2000);
  }

  private async attachmentsUpload(messagesExclude: Message[]) {
    const param = () => ({
      ...this.mutatedParameters,
      apiKey: this.requestedParameters.apiKey,
    });
    const newMsg: Message[] = [];
    let uploadFileIds: string[] = [];
    this.loadedStatus('file uploaded');
    for (const m of this.messages) {
      const { msg, addFilesIds, context } = await fileUpload(m, param());
      uploadFileIds.push(...addFilesIds);
      this.yChat.setMessage(msg);
      newMsg.push(msg);
      this.mutatedParameters.context = context;
    }
    this.messages = newMsg;

    const isAttach = !!this.messages.find((m) =>
      Boolean(m.attachments?.length),
    );

    this.loadedStatus('stor update');
    const { context } = await actionsStore(
      isAttach,
      uploadFileIds,
      messagesExclude,
      param(),
    );
    this.mutatedParameters.context = context;
    this.yChat.aiContext = context;

    this.mutatedMessages = this.messages.map((m) => ({
      role: m.role,
      content: m.content,
      attachments: m.attachments
        ?.map((a) => {
          if (FileUploaded.is(a)) return a.data;
        })
        .filter(
          Boolean,
        ) as OpenAI.Beta.Threads.ThreadCreateParams.Message.Attachment[],
    }));

    return { isAttach };
  }

  private getSystemPrompt() {
    const systemPrompt = (
      AppConfig.config?.SYSTEM_PROMPT ||
      this.pluginOptions.getValidatedOption<string>(
        'system-prompt',
        'systemPrompt',
        this.chat.id,
      )
    ).replace('{{ datetime }}', new Date().toLocaleString());

    const list =
      this.pluginOptions.getValidatedOption<MemoryPluginOptions['list']>(
        'memory',
        'list',
      ) || [];

    const hash = list
      .filter((e) => (Date.now() - e.timestamp) / 1000 / 60 / 60 / 24 < 30)
      .filter((e) => e.chatId !== this.chat.id)
      .sort((a, b) => a.timestamp - b.timestamp)
      .reduce<string>((acc, e) => {
        if (!e.value) return acc;
        if (acc.length + e.value.length > 256_000) return acc;
        return acc + ' \n' + e.value;
      }, '');

    return hash + '\n' + systemPrompt;
  }

  public async execute(messagesExclude: Message[], newThread = false) {
    try {
      await pluginRunner(
        'postprocess-model-input',
        this.pluginContext,
        async (plugin) => {
          const { messages, parameters } = await plugin.preprocessModelInput(
            this.mutatedMessages,
            this.mutatedParameters,
          );

          this.mutatedMessages = messages;
          this.mutatedParameters = parameters;
        },
      );

      this.scheduleTimeout();
      const { isAttach } = await this.attachmentsUpload(messagesExclude);
      let stream: {
        emitter: EventEmitter;
        cancel: () => void;
      };

      const param = {
        ...this.mutatedParameters,
        apiKey: this.requestedParameters.apiKey,
      };
      const systemPrompt = {
        role: 'system',
        content: this.getSystemPrompt(),
      };
      if (isAttach || AppConfig.config?.ONLY_THREAD) {
        const emitterStatus = new EventEmitter();
        emitterStatus.on('serpStatus', (v) => this.serp(v));
        emitterStatus.on('loadedStatus', (v) => this.loadedStatus(v));
        const { context, ...s } = await createThreadChat(
          systemPrompt.content,
          this.mutatedMessages,
          param,
          newThread,
          emitterStatus,
        );
        stream = s;
        if (context) this.yChat.aiContext = context;
      } else {
        stream = await createStreamingChatCompletion(
          [systemPrompt, ...this.mutatedMessages],
          param,
        );
      }

      this.cancelRequestStream = stream.cancel;

      const eventIterator = new EventEmitterAsyncIterator<string>(
        stream.emitter,
        ['data', 'done', 'error'],
      );

      for await (const event of eventIterator) {
        const { eventName, value } = event;

        switch (eventName) {
          case 'data':
            await this.onData(value);
            break;
          case 'done':
            await this.onDone();
            break;
          case 'error':
            if (!this.content || !this.done) {
              await this.onError(value);
            }
            break;
        }
      }
    } catch (e: any) {
      console.error(e);
      this.onError(e.message);
    }
  }

  public async onData(value: any) {
    if (this.done) return;

    this.lastChunkReceivedAt = Date.now();

    this.content = value;

    this.yChat.setMessageInit(this.replyID);

    await pluginRunner(
      'postprocess-model-output',
      this.pluginContext,
      async (plugin) => {
        const output = await plugin.postprocessModelOutput(
          {
            role: 'assistant',
            content: this.content,
          },
          this.mutatedMessages,
          this.mutatedParameters,
          false,
        );

        this.content = output.content;
      },
    );

    this.yChat.setPendingMessageContent(this.replyID, this.content);
  }

  private async onDone() {
    if (this.done) return;

    clearInterval(this.timer);
    this.lastChunkReceivedAt = Date.now();
    this.done = true;
    this.emit('done');

    this.yChat.onMessageDone(this.replyID);
    this.yChat.setMessageInit(this.replyID);
    this.yChat.setMessageContent(this.replyID, this.content);

    await pluginRunner(
      'postprocess-model-output',
      this.pluginContext,
      async (plugin) => {
        const output = await plugin.postprocessModelOutput(
          {
            role: 'assistant',
            content: this.content,
          },
          this.mutatedMessages,
          this.mutatedParameters,
          true,
        );

        this.content = output.content;
      },
    );
  }

  private loadedStatus(status: string) {
    if (this.done) return;
    this.emit('loadedStatus', status);
  }

  private serp(status: string) {
    this.yChat.setMessageSerp(this.replyID, status as Message['serp']);
  }

  private async onError(error: string) {
    if (this.done) return;
    this.done = true;
    this.emit('done');
    clearInterval(this.timer);
    this.cancelRequestStream?.();

    this.content += `\n\nI'm sorry, I'm having trouble connecting to OpenAI (${error || 'no response from the API'}). Please make sure you've entered your OpenAI API key correctly and try again.`;
    this.content = this.content.trim();

    this.yChat.setMessageContent(this.replyID, this.content);
    this.yChat.onMessageDone(this.replyID);
    this.yChat.setMessageInit(this.replyID);
  }

  public cancel() {
    clearInterval(this.timer);
    this.done = true;
    this.yChat.onMessageDone(this.replyID);
    this.yChat.setMessageInit(this.replyID);
    this.cancelRequestStream?.();
    this.emit('done');
  }
}
